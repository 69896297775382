<template>
    <div @click="hideFollowModal" style="height:100%">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
            <a-breadcrumb-item>邀约到访</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-body">
            <a-tabs :animated="false" :activeKey="acitveKey" @change="changeTab">
                <a-tab-pane tab="列表模式" key="1">
                    <inviteList ref="inviteList" v-if="acitveKey === '1'"/>
                </a-tab-pane>
                <a-tab-pane tab="视图模式" key="2">
                    <inviteView ref="inviteView" v-if="acitveKey === '2'"/>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    import inviteList from './list'
    import inviteView from './view'

    export default {
        name:'invite',
        components: {
            inviteList,
            inviteView
        },
        data() {
            return {
                acitveKey: '1'
            }
        },
        methods: {
            changeTab(key) {
                this.acitveKey = key
            },
            hideFollowModal(){
                if(this.acitveKey === '1'){
                    this.$refs.inviteList.followVisible = false
                }else{
                    this.$refs.inviteView.followVisible = false
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>