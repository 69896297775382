<template>
    <div @click="hideFollowModal" style="height:100%">
        <div>
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item v-if="canExcelexport">
                            <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                                <a-select-option :value="0">我的</a-select-option>
                                <a-select-option :value="1">我下属的</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-input v-model='searchParams.search.keyword'  style="width: 160px" allowClear placeholder="客户名称/手机号"></a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-select v-model='searchParams.search.studio_id' style="width: 200px" mode="multiple" :maxTagCount="1" :maxTagTextLength='5'  allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <!-- <a-form-item>
                            <a-input v-model.trim="searchParams.search.cellphone"  allowClear placeholder="联系电话" style="width: 140px"></a-input>
                        </a-form-item> -->
                        <a-form-item v-if="canIndex">
                            <a-button @click="searchList" html-type="submit" :loading="loading" type="primary" >搜索</a-button>
                        </a-form-item>
                        <a-form-item>
                                <a-popover  placement="bottom" trigger="click" v-model="popVisible">
                                <template slot="content">
                                    <div class="more-search">
                                    <a-form layout='inline'>
                                        <a-form-item class="block-line" label="来源渠道">
                                            <a-tree-select
                                                v-model="searchParams.search.channel_id"
                                                :tree-data="channels"
                                                tree-checkable
                                                allowClear
                                                treeNodeFilterProp="title"
                                                :maxTagCount='1'
                                                :maxTagTextLength='5'
                                                :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                                :show-checked-strategy="SHOW_ALL"
                                                placeholder="请选择来源渠道"
                                            />
                                        </a-form-item>
                                        <a-form-item class="block-line" label="意向科目">
                                            <a-select v-model='searchParams.search.course_cate_id' allowClear showSearch placeholder="请选择意向科目" :filterOption="filterOption">
                                            <a-select-option v-for="(d, index) of classCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                                            </a-select>
                                        </a-form-item>
                                        <a-form-item class="block-line" label="意向程度">
                                            <a-select v-model='searchParams.search.intention_level' allowClear placeholder="请选择意向程度">
                                            <a-select-option v-for="(d, index) of intentionLevels" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                            </a-select>
                                        </a-form-item>
                                        <a-form-item class="block-line" label="到访状态">
                                            <a-select v-model='searchParams.search.is_attend' allowClear placeholder="请选择到访状态">
                                            <a-select-option v-for="(d, index) of isAttends" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                            </a-select>
                                        </a-form-item>
                                        <a-form-item v-if="searchParams.search.is_sub == 1" class="block-line" label="课程顾问">
                                            <a-select v-model='searchParams.search.course_consultant_id' allowClear showSearch placeholder="请选择课程顾问" :filterOption="filterOption">
                                            <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                                            </a-select>
                                        </a-form-item>
                                    </a-form>
                                    <div class="more-search__buttons">
                                        <a-button type="dashed" @click="closeSearch">取消</a-button>
                                        <a-button @click="reset">重置</a-button>
                                        <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                                    </div>
                                    </div>
                                </template>
                                <a-button>更多<a-icon type="down" /></a-button>
                                </a-popover>
                            </a-form-item>
                    </a-form>
                </div>
            </div>
            <div @click.stop>
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <full-calendar 
                    class='view-full-calendar'
                    ref="calendar"
                    :events="events" 
                    :config="config"  
                    locale="fr">
                </full-calendar>
            </div>
        </div>
        <followModal :authType="authType" :item="modalData" :leadsId='leadsId' isTab='1' v-if="followVisible"/>
    </div>
</template>

<script>

import C_ITEMS from '@/utils/items'
import moment from 'moment'
import authority from '@/common/mixins/authority'
import uploadModal from '@/views/clientresource/modal/uploadModal'
import ranges from "@/common/mixins/ranges"
import followModal from '@/views/clientresource/modal/followModal/index'

    export default {
        name:'invite',
        provide() {
            return {
                parent: this
            }
        },
        components: {
            followModal,
        },
        data() {
            return {
                loading: false,
                searchParams: {
                    "search": {
                        is_sub:0,
                        promise_start_date:moment().startOf('week').format('YYYY-MM-DD'),
                        promise_end_date:moment().endOf('week').format('YYYY-MM-DD')
                    },
                    unlimit:1

                },
                authType:['crm','inviteattend'],
                events:[],
                config:{
                    firstDay:1,
                    locale:'zh-cn',
                    defaultView: 'basicWeek',
                    height:'300px',
                    header:{
                        left:'',
                        center:'title',
                        right:'prev,today,next',
                    },
                    /* 设置按钮文字 */
                    buttonText:{
                        today:'本周',
                    },
                    editable:false,
                    eventClick: this.eventClick,
                    viewRender: this.viewRenderAction,

                },
                exportLoading:false,
                popVisible:false,
                studios:[],
                channels:[],
                channelsId:[],
                consultants:[],
                classCategorys:[],
                intentionLevels:C_ITEMS.intentionLevelsF,
                isAttends: C_ITEMS.isAttendsF,
                modalData: {},
                leadsId:'',
                followVisible:false
            }
        },
        mixins: [ authority , ranges ],
        beforeCreate () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }
        },
        created(){
            this.getStudio()
            this.getSourceChannel()
            this.getConsultant()
            this.getClassCategory()
        },
        methods: {
            moment,
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList() {
                if(!this.canIndex){
                    this.$message.warning('您暂无权限!!')
                    return false
                }
                this.loading = true
                this.searchParams.search.is_sub = this.$ls.get('is_sub')
                let res = await this.$store.dispatch('cInviteAttendAction', { data: this.searchParams })
                let list = []
                res.items.forEach(item=>{
                    let obj = {
                        id:item.task_id,
                        title:`
                            学员名称: ${item.customer_name}
                            联系电话: ${item.cellphone}
                            校区名称: ${item.studio_id}
                            负责人:  ${item.course_consultant_id} 
                            备注:  ${item.remark || ''}`,
                        start:moment(item.promise_date_time).format('YYYY/MM/DD HH:mm'),
                        end:moment(item.promise_date_time).format('YYYY/MM/DD HH:mm'),
                        details:item
                    }
                    if(item.intention_level == 9){
                        obj.color = 'red'
                    }
                    list.push(obj)
                })
                this.events = list
                this.loading = false
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {})
                this.studios = res.data
            },
            async getSourceChannel(id) {
                let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
                this.channels = res.data
            },
            async getConsultant() {
                let res = await this.$store.dispatch('searchConsultantAction', {})
                this.consultants = res.data
            },
            async getClassCategory() {
                let res = await this.$store.dispatch('searchCourseTypeAction', {})
                this.classCategorys = res.data
            },
            async toExport(){
                this.exportLoading = true
                let exportData = {
                    search:this.searchParams.search,
                    visual:1
                }
                exportData.search.task_id = this.selectedRowKeys
                let res = await this.$store.dispatch('cInviteAttendExportAction', exportData)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `邀约到访导出.${moment().format('YYYY.MM.DD')}.xlsx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            },
            searchList(e){
                e?e.preventDefault():''
                this.getList()
            },
            reset() {
                this.searchParams.search = {}
            },
            handleIsSub(e){
                this.$ls.set('is_sub', e)
                this.searchParams.search.is_sub = e
            },
            closeSearch() {
                this.popVisible = false
            },
            eventClick(e){
                this.modalData = e.details
                this.leadsId = e.details.customer_id
                this.followVisible = true
            },
            viewRenderAction(e) {
                this.searchParams.search.promise_start_date = moment(e.start).format('YYYY-MM-DD')
                this.searchParams.search.promise_end_date = moment(e.end).add(-1,'day').format('YYYY-MM-DD')
                this.getList()
            },
            hideFollowModal(type) {
                if (type === 1) {
                    this.getList()
                }
                this.followVisible = false
            },
        },
    }
</script>

<style lang="scss">
    .view-full-calendar{
        .fc-day-grid-event .fc-content{
            white-space: inherit
        }
    }
</style>