<template>
    <div @click="hideFollowModal" style="height:100%">
        <div>
        <div class="clearfix table-tools">
            <div class="buttons">
                <a-form layout='inline'>
                    <a-form-item v-if="canExcelexport">
                        <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-dropdown :visible="showColumns">
                            <a-menu @mouseleave="showColumns = false" slot="overlay">
                                <a-menu-item :key="index" v-for="(item,index) in columns">
                                    <a-checkbox @change="onChangeColspan(item)" :checked="item.show_colspan">{{item.title}}</a-checkbox>
                                </a-menu-item>
                            </a-menu>
                            <a-button @click="showColumns = true"> 选择列 <a-icon type="down" /> </a-button>
                        </a-dropdown>
                    </a-form-item>
                </a-form>
            </div>
            <div class="search">
                <a-form layout='inline' @submit="searchList">
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                            <a-select-option :value="0">我的</a-select-option>
                            <a-select-option :value="1">我下属的</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-input v-model='searchParams.search.keyword'  style="width: 160px" allowClear placeholder="客户名称/手机号"></a-input>
                    </a-form-item>
                    <!-- <a-form-item>
                        <a-input v-model.trim="searchParams.search.cellphone"  allowClear placeholder="联系电话" style="width: 140px"></a-input>
                    </a-form-item> -->
                    <a-form-item>
                        <a-tooltip title="访诺时间">
                            <a-range-picker
                            :ranges="rangesData"
                            v-model='searchDatas.promise'
                            allowClear
                            @change="(val,time)=>handleTime(val,time,'promise')" style="width: 240px"/>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-select v-model='searchParams.search.studio_id' style="width: 200px" mode="multiple" :maxTagCount="1" :maxTagTextLength='5'  allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item v-if="canIndex">
                        <a-button @click="searchList" html-type="submit" :loading="loading" type="primary" >搜索</a-button>
                    </a-form-item>
                    <a-form-item>
                            <a-popover  placement="bottom" trigger="click" v-model="popVisible">
                            <template slot="content">
                                <div class="more-search">
                                <a-form layout='inline'>
                                    <a-form-item class="block-line" label="来源渠道">
                                        <a-tree-select
                                            v-model="searchParams.search.channel_id"
                                            :tree-data="channels"
                                            tree-checkable
                                            allowClear
                                            treeNodeFilterProp="title"
                                            :maxTagCount='1'
                                            :maxTagTextLength='5'
                                            :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                            :show-checked-strategy="SHOW_ALL"
                                            placeholder="请选择来源渠道"
                                        />
                                    </a-form-item>
                                    <a-form-item class="block-line" label="意向科目">
                                        <a-select v-model='searchParams.search.course_cate_id' allowClear showSearch placeholder="请选择意向科目" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of classCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="意向程度">
                                        <a-select v-model='searchParams.search.intention_level' allowClear placeholder="请选择意向程度">
                                        <a-select-option v-for="(d, index) of intentionLevels" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="跟进状态">
                                        <a-select mode="multiple" v-model='searchParams.search.task_step' allowClear placeholder="请选择跟进状态">
                                        <a-select-option v-for="(d, index) of dealStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="到访状态">
                                        <a-select v-model='searchParams.search.is_attend' allowClear placeholder="请选择到访状态">
                                        <a-select-option v-for="(d, index) of isAttends" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>

                                    <a-form-item v-if="searchParams.search.is_sub == 1" class="block-line" label="负责人">
                                        <a-select v-model='searchParams.search.course_consultant_id' allowClear showSearch placeholder="请选择负责人" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>

                                    <a-form-item class="block-line" label="登记人">
                                        <a-select v-model='searchParams.search.attend_by' allowClear showSearch placeholder="请选择登记人" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>

                                    <a-form-item class="block-line" label="实际到访">
                                        <a-range-picker
                                        :ranges="rangesData"
                                        v-model='searchDatas.attend'
                                        allowClear
                                        @change="(val,time)=>handleTime(val,time,'attend')" />
                                    </a-form-item>

                                    <a-form-item class="block-line" label="邀约时间">
                                        <a-range-picker
                                        :ranges="rangesData"
                                        v-model='searchDatas.invited'
                                        allowClear
                                        @change="(val,time)=>handleTime(val,time,'invited')" />
                                    </a-form-item>

                                    
                                </a-form>
                                <div class="more-search__buttons">
                                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                                    <a-button @click="reset">重置</a-button>
                                    <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                                </div>
                                </div>
                            </template>
                            <a-button>更多<a-icon type="down" /></a-button>
                            </a-popover>
                        </a-form-item>
                </a-form>
            </div>
        </div>
        <div @click.stop class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="middle" :pagination="false" :bordered='false' rowKey="task_id"
                :selection="{key: 'key'}" :scroll="{ x: 1900 }"
                :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                :columns="columnsList" :dataSource="list" :customRow="clickRow" :rowClassName="rowClassName" @change="handleChange">
                <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>

                <template slot="name" slot-scope="text, record">
                    <div style="">
                        <a style="display: block;max-width:90px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">{{ text }}</a> 
                        <!-- <svg style="width: 16px;height: 16px;margin-left: 6px;" aria-hidden="true">
                            <use v-show="record.gender == 2" xlink:href="#icon-sex_woman"></use>
                            <use v-show="record.gender == 1" xlink:href="#icon-sex_man"></use>
                        </svg> -->
                    </div>
                    <span style="font-size:10px;color: rgba(69, 90, 100, 0.6);">
                        ({{record.gender}}{{record.year?','+record.year+'岁':''}})                             
                    </span>
                </template>

                 <template slot="cellphone" slot-scope="text,record">
                    <div @click.stop v-clipboard:copy="text" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        {{record.cellphone}}
                        <a-icon type="copy" :style="{ fontSize: '12px'}" />
                    </div>
                    <span style="font-size:10px;color: rgba(69, 90, 100, 0.6);" v-if="record.cellphone_geo">
                        ({{record.cellphone_geo.province}},{{record.cellphone_geo.op}})
                    </span>
                </template>

                <template slot="level" slot-scope="text, record">
                    <div @click.stop>
                        <a-dropdown placement="bottomCenter" :trigger="['click']">
                            <span style='cursor:pointer' @click="e => e.preventDefault()" v-if='text == null'>--</span>
                            <!-- <span v-if='text == item.value' @click="e => e.preventDefault()" class="follow-intentionLevels" :style="{color:text == item.value?'#fff':item.color,background:text == item.value?item.color:'#fff'}" v-for="item in intentionLevels" :key="item.value">{{item.label}}</span> -->
                            <span @click="e => e.preventDefault()" class="follow-intentionLevels" v-if='text == 9' style="color:#fff;background:#ED6363">靶子</span>
                            <span @click="e => e.preventDefault()" class="follow-intentionLevels" v-else style="color:#fff;background:#ccc">其他</span>
                            <a-menu slot="overlay" class='customer-menu'>
                                <a-menu-item @click='handleLevel(item,record)' v-for="item in intentionLevels" :key='item.value'>
                                    <span class="follow-intentionLevels" :style="{color:text == item.value?'#fff':item.color,background:text == item.value?item.color:'#fff'}">{{item.label}}</span>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                </template>
                <template slot="null_txt" slot-scope="text">
                        <span v-if="text" class="short_content">
                        <a-tooltip>
                            <template slot="title">
                            {{text}}
                            </template>
                            {{text}}
                        </a-tooltip>
                        </span>
                        <span v-else>--</span>
                </template>

                <template slot="task_step" slot-scope="text,record">
                    <a-tag style='cursor:pointer' v-for="item in dealStatus" :key='item.value' :color="item.color"  v-if="text==item.value">
                        {{item.label}}
                    </a-tag>
                </template>

                <template slot="promise" slot-scope="text">
                    <span v-if="text">{{moment(text).format("YYYY") == moment().format("YYYY")?moment(text).format("M月D日 HH:mm"):moment(text).format("YYYY年M月D日 HH:mm")}}</span>
                    <span v-else>--</span>
                </template>

                <template slot="is_attend" slot-scope="text,record">
                    <a-tag color="green" v-if="text==1">
                        已到访
                    </a-tag>
                    <a-tag color="volcano" v-if="text === -1 || text === 0">
                        <a-icon title="到访确认已推送" type="sound" v-if="record.is_notify" /> 未到访
                    </a-tag>    
                </template>

                <template slot="deal_status" slot-scope="text,record">
                        <a-tag color="green" v-if="text==1">
                            在读学员
                        </a-tag>
                        <a-tag color="orange" v-if="text==0">
                            潜在学员
                        </a-tag>    
                    </template>

                <template slot="is_bind_qywx" slot-scope="text, record">
                    <svg style="width:16px;height: 16px;vertical-align: middle;" aria-hidden="true">
                        <use v-show="record.is_bind_qywx == 1" xlink:href="#icon-qiyeweixin"></use>
                        <use v-show="record.is_bind_qywx == 0" xlink:href="#icon-qiyeweixin-unbind"></use>
                    </svg>
                </template>

                <template v-if="canAttend" slot="action" slot-scope="text,record">
                    <a @click.stop='showTimeModal(record)' href="javascript:;" v-if="record.is_attend<=0">已到访</a>
                    <a href="javascript:;" @click.stop="handleNoShow(record)" v-if="record.is_attend==1">未到访</a>

                </template>
            </a-table>
        </div>
        <div class="page">
            <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
                </template>
            </a-pagination>
        </div>
        </div>
        <followModal :authType="['crm','customer']" :item="modalData" :leadsId='leadsId' isTab='1' v-if="followVisible"/>
        <uploadModal v-if="isUploadModal" isTab='1' />
        <attendModal v-if="isAttendModal" :item="modalData" />

    </div>
</template>

<script>
const columns = [
  { title: '#', width:'35px', scopedSlots: { customRender: 'index' }, key: 'index',fixed: 'left',show_colspan: true,ellipsis: true },
  {title: '客户名称',width:"100px", dataIndex: 'customer_name',key: '1' ,fixed: 'left', scopedSlots: { customRender: 'name' },show_colspan: true},
//   {title: '性别', width:'45px', dataIndex: 'gender', key: 'gender',align:"center" ,fixed: 'left'},
//   {title: '年龄', width:'45px', dataIndex: 'year', key: 'year',align:"center" ,fixed: 'left'},
  {title: '联系电话', width:'120px',dataIndex: 'cellphone', key: 'cellphone' ,fixed: 'left',scopedSlots: { customRender: 'cellphone' },show_colspan: true},
  {title: '', width: '30px', dataIndex: 'is_bind_qywx', key: 'is_bind_qywx', fixed: 'left', scopedSlots: { customRender: 'is_bind_qywx' } ,show_sorter: true,show_colspan: true},
  {title: '学员类型', width:'100px', dataIndex: 'deal_status', key: 'deal_status' , fixed: 'left',scopedSlots: { customRender: 'deal_status' },show_sorter: true, show_colspan: true},
  {title: '到访状态', dataIndex: 'is_attend',width:'100px', key: 'is_attend', scopedSlots: { customRender: 'is_attend' },show_sorter: true,show_colspan: true},
  {title: '跟进状态', width:'100px',dataIndex: 'task_step', key: 'task_step' ,scopedSlots: { customRender: 'task_step' },show_sorter: true,show_colspan: true,align:"left",},
  {title: '最后跟进', dataIndex: 'recent_contact', key: 'recent_contact' ,show_sorter: true,sorter:()=>{},show_colspan: true},
  {title: '跟进内容', dataIndex: 'last_follow', key: 'last_follow' , show_colspan: true,scopedSlots: { customRender: 'null_txt' }},
  {title: '归属校区', dataIndex: 'studio_id', key: 'studio_id',ellipsis: true ,show_sorter: true,show_colspan: true},
  {title: '承诺到访', dataIndex: 'promise_date_time', key: 'promise_date_time', scopedSlots: { customRender: 'promise' } ,show_sorter: true,show_colspan: true},
  {title: '负责人', width:"80px",dataIndex: 'course_consultant_id', key: 'course_consultant_id',ellipsis: true,show_sorter: true,show_colspan: true},
  {title: '实际到访', dataIndex: 'attend_time', key: 'attend_time' , scopedSlots: { customRender: 'promise' },show_sorter: true,show_colspan: true},
  {title: '登记人', width:"80px", dataIndex: 'attend_by', key: 'attend_by',ellipsis: true,show_colspan: true},
  {title: '意向课程',width:"100px", dataIndex: 'course_cate_id', key: 'course_cate_id' , show_sorter: true,ellipsis: true,show_colspan: true},
  {title: '意向程度',width:"100px", dataIndex: 'intention_level', key: 'intention_level' , scopedSlots: { customRender: 'level' },show_sorter: true,align:'center',show_colspan: true},
  {title: '来源渠道', dataIndex: 'channel_id', key: '2',ellipsis: true ,show_sorter: true,show_colspan: true},
  {title: '联系次数', width:"80px",dataIndex: 'contact_count', key: 'contact_count',align:"center",show_colspan: true},
  {title: '添加人', width:"80px", dataIndex: 'created_by', key: 'created_by' ,show_sorter: true,show_colspan: true},
  { title: '操作', key: 'operation',fixed:'right', width:60,align:"center",scopedSlots: { customRender: 'action' },show_colspan: true}
]


import C_ITEMS from '@/utils/items'
import moment from 'moment'
import tableMixins from '@/common/mixins/table'
import authority from '@/common/mixins/authority'
import uploadModal from '@/views/clientresource/modal/uploadModal'
import ranges from "@/common/mixins/ranges"
import followModal from '@/views/clientresource/modal/followModal/index'
import attendModal from './attendModal'

    export default {
        name:'invite',
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                loading: false,
                isAttendModal: false,
                popVisible: false,
                showColumns:false,
                confirmLoading: false,
                pageSizeOptions: ['10', '20', '30', '40', '100'],
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                columns,
                columnsList:[],
                intentionLevels:C_ITEMS.intentionLevelsF,
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {
                        is_sub:0,
                        promise_start_date:moment().format('YYYY-MM-DD'),
                        promise_end_date:moment().add(1,'month').format('YYYY-MM-DD')
                    },
                    "sort": ''
                },
                selectedRowKeys:[],
                searchDatas:{},
                modalData: {},
                isUploadModal:false,
                followVisible:false,
                exportLoading:false,
                leadsId:'',
                clickId:'',
                studios:[],
                channels:[],
                channelsId:[],
                consultants:[],
                classCategorys:[],
                customerName:'',
                phoneNumber:undefined,
                studioId:undefined,
                authType:['crm','inviteattend'],
                isAttends: C_ITEMS.isAttendsF,
                intentionLevels:C_ITEMS.intentionLevelsF,
                dealStatus:C_ITEMS.dealStatus,
                paramsData:{
                    start_date:'',
                    end_date:'',
                },
            }
        },
        mixins: [ tableMixins , authority , ranges],
        components:{followModal,uploadModal,attendModal},
        beforeCreate () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }
        },
        created(){
            // this.$ls.remove('invite_columnsList')//该行代码2021-11-3可删除
            // if(this.$ls.get('invite_columnsList')){
            //     this.columns = this.$ls.get('invite_columnsList')
            //     this.columns.forEach(item=>{
            //         if(item.show_colspan){
            //             this.columnsList.push(item)
            //         }
            //     })
            // }else{
                this.columnsList = this.columns
            //     this.$ls.set('invite_columnsList', this.columns)
            // }
            this.columnsList.forEach(item=>{
                if(item.show_sorter){
                    item.sorter = ()=>{}
                    item.sortDirections= ['descend', 'ascend'];
                }
            })
            this.searchDatas.promise = [moment(),moment().add(1,'month')]
            this.getStudio()
            this.getSourceChannel()
            this.getConsultant()
            this.getClassCategory()
        },
        methods: {
            onCopy(){
                this.$store.dispatch('toolsClickAction', {data: {event_type:'event_invite_cellphone_click'}})
                this.$message.success('已复制到剪切板!')
            },
            onError(){
                this.$message.error('操作失败!')
            },
            moment,
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList() {
                if(!this.canIndex){
                    this.$message.warning('您暂无权限!!')
                    return false
                }
                this.loading = true
                this.searchParams.search.is_sub = this.$ls.get('is_sub')
                let res = await this.$store.dispatch('cInviteAttendAction', { data: this.searchParams })
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {})
                this.studios = res.data
            },
            async getSourceChannel(id) {
                let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
                this.channels = res.data
            },
            async getConsultant() {
                let res = await this.$store.dispatch('searchConsultantAction', {})
                this.consultants = res.data
            },
            async getClassCategory() {
                let res = await this.$store.dispatch('searchCourseTypeAction', {})
                this.classCategorys = res.data
            },
            showUploadModal(){
                this.isUploadModal = true
            },
            hideUploadModal(){
                this.isUploadModal = false
            },
            closeSearch() {
                this.popVisible = false
            },
            handleTime(val,time,name){
                this.searchDatas[name] = val
                this.$forceUpdate()
                let start_date = name + '_start_date'
                let end_date = name + '_end_date'
                this.searchParams.search[start_date] = time[0]
                this.searchParams.search[end_date] = time[1]
                if(this.name = 'promise'){
                    this.searchList()
                }
            },
            clickRow(record, index) {
                return {
                    on: {
                        click: () => {
                            this.clickId = record.customer_id
                            this.showFollowModal(null, record)
                        }
                    }
                }
            },
            rowClassName(record, index) {
                let className = "";
                if (record.customer_id == this.clickId) {
                    className = "rowActive";
                    this.$emit('change', this.clickId)
                }
                return className;
            },
            showFollowModal(e, item) {
                e && e.stopPropagation()
                this.modalData = item
                this.leadsId = item.customer_id
                this.followVisible = true
                return 
            },
            hideFollowModal(type) {
                if (type === 1) {
                    this.getList()
                }
                this.followVisible = false
            },
            handleChange(pagination, filters, sorter){
                if(sorter.order){
                    if(sorter.order === "ascend"){
                        this.searchParams['sort'] = `${sorter.field}`
                    }else{
                        this.searchParams['sort'] = `-${sorter.field}`
                    }
                }else{
                    this.searchParams['sort'] = ''
                    }
                this.getList()
            },
            handleNoShow(val){
                let that = this
                this.$confirm({
                    title: `您确定修改到访状态？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        await that.$store.dispatch('cInviteAttendNoShowAction', { data: { task_id: val.task_id } })
                            .then(res=>{
                                that.$message.success('操作成功~')
                                that.getList()
                                resolve(res)
                            })
                            .catch(err=>{
                                reject(err)
                            })
                    }).catch(error => console.log(error))
                    }
                })
            },
            showTimeModal(val){
                this.modalData = val
                this.isAttendModal = true
            },
            hideAttendModal(val){
                if(val){
                    this.getList()
                }
                this.isAttendModal = false
            },
            handleIsSub(e){
                this.$ls.set('is_sub', e)
                this.searchParams.search.is_sub = e
            },
            onChangeColspan(item){
                item.show_colspan = !item.show_colspan
                this.$ls.set('invite_columnsList', this.columns)
                let arr = []
                this.columns.forEach(item=>{
                    if(item.show_colspan){
                        arr.push(item)
                    }
                })
                this.columnsList = arr
            },
            async handleLevel(item,record){
                let params = {
                    intention_level:item.value,
                    task_id:record.task_id
                }
                let res = await this.$store.dispatch('cCustomerTaskUpdateAction', params)
                this.getList()
                this.$message.success('操作成功~')
            },
            async toExport(){
                this.exportLoading = true
                let exportData = {
                    search:this.searchParams.search,
                    sort:this.searchParams.sort
                }
                exportData.search.task_id = this.selectedRowKeys
                let res = await this.$store.dispatch('cInviteAttendExportAction', exportData)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `邀约到访导出.${moment().format('YYYY.MM.DD')}.xlsx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            },
        },
    }
</script>
<style lang="scss">
  .rowActive{
    background:#e6fff6
  }
  .follow-intentionLevels{
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        border: 1px solid;
        margin: 0 5px;
        cursor: pointer;
    }
    .short_content{
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        display: -moz-box;
        -moz-line-clamp: 2;
        -moz-box-orient: vertical;

        overflow-wrap: break-word;
        word-break: break-all;
        white-space: normal;
        overflow: hidden;
    }
   .customer-menu{
       display:flex;
       .ant-dropdown-menu-item{
           padding:5px 3px;
       }
   }
</style>