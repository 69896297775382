<template>
    <a-modal title="确认到访" v-model="visible" :confirmLoading="confirmLoading"
        cancelText="取消" okText="确认" width="400px" 
        @ok="handleOk" @cancel="handleCancel">
            <a-form :form="form" layout="vertical">
                <a-form-item v-for="(item,index) in formData" :key="index" :label="item.label">
                    <a-date-picker v-if="item.type === 'date'" style="width: 100%" :popupStyle='{zIndex:4200}'  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD HH:mm"/>
                    <a-upload
                        v-decorator="[item.name, {valuePropName: 'fileList',getValueFromEvent: normFile, rules: item.rules }]"
                        v-if="item.type === 'upload'"
                        accept='.png,.jpeg,.jpg'
                        list-type="picture-card"
                        :data="uploadParams"
                        :action="uploadUrl"
                        :multiple='true'
                        :beforeUpload="beforeUploadImg"
                        @change="changeImg">
                    <div>
                <a-icon type="plus" />
            </div>
        </a-upload>
                </a-form-item>
            </a-form>
    </a-modal>
</template>

<script>
const formData = [
    {
        name: 'attend_time',
        label: '访诺时间',
        type: 'date',
        rules: [{ required: true, message: '请选择访诺时间!' }],
    },
    {
        name: 'file_list',
        label: '到访图片',
        type: 'upload',
        rules: [{ required: true, message: '请上传到访图片!' }],
    },
]
    import url from '@/utils/URL'
    import moment from 'moment'
    import createFilePath from '@/utils/tools'
    export default {
        name:"attendModal",
        inject: ['parent'],
        props:{
            item:Object
        },
        data() {
            return {
                formData,
                visible: false,
                confirmLoading: false,
                imgList:[],
                imgAddr:[],
                uploadParams: {},
                uploadUrl: url.uploadQiNiu,
                form: this.$form.createForm(this),
            }
        },
        async created () {
            this.visible = true
            await this.$nextTick()
            this.form.setFieldsValue({attend_time:moment()})
        },
        methods: {
            async handleOk(){
                let params = this.form.validateFields()
                let images = []
                this.imgList.forEach(item=>{
                    images.push(item.response.key)
                })
                let obj = {
                    task_id:this.item.task_id,
                    attend_time:moment(params.attendTime).format('YYYY-MM-DD HH:mm'),
                    attend_images:images
                }
                this.confirmLoading = true
                await this.$store.dispatch('cInviteAttendAttendAction', { data: obj })
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.parent.hideAttendModal(1)
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                })
            },
            handleCancel(){
                this.parent.hideAttendModal()
            },
            async beforeUploadImg(file,fileList) {
                if (!file) { return false }
                let res = await this.$store.dispatch('imageTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    let obj = {
                        uid:file.uid,
                        url:this.uploadParams.key,
                    }
                    this.imgAddr.push(obj)
                    return true
                }
                return false
            },
            async changeImg({fileList}){
                var arr = fileList
                arr.forEach((item,index)=>{
                    var isJpgOrPng = item.type === 'image/jpeg' || item.type === 'image/png'
                    if(!isJpgOrPng){
                        arr.splice(index,1)
                    }
                })
                this.imgList = arr
            },
            normFile(e) {
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>