<template>
  <div class='crmUploadModal'>
  <a-modal :title="modalTitle" v-model="visible" centered :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="550px" :footer="null" :zIndex='4000' :closable='isUpload?false:true' :maskClosable='isUpload?false:true'
    @ok="handleOk" @cancel="handleCancel">
    <div class="uploadModal">
        <a-tabs :animated="false" :activeKey='activeKey' @change='tabClick'>
            <a-tab-pane disabled key="1" tab="">
            </a-tab-pane> 
            <a-tab-pane key="2" tab="上传文件">
                <div class="uploadModal-content">
                    <div>
                        <div class="uploadModal-content-title">
                            <span>一、请按照数据模板的格式准备要导入的数据。</span>
                            <span @click="toDown" style="color:#00cca2;cursor:pointer">点击下载</span>
                            <span v-if="isTab == '0'">《线索导入模板》</span>
                            <span v-if="isTab == '1'">《客户导入模板》</span>
                        </div>
                        <div style="color:#a9a9a9;font-size:12px;padding:10px 10px 10px 30px">
                            注意事项：<br/>
                            1、模板中的表头名称不能更改，表头行不能删除<br/>
                            2、其中标*为必填项，必须填写<br/>
                            3、导入文件请勿超过10MB<br/>
                        </div>
                    </div>
                    <div>
                        <div class="uploadModal-content-title">
                            <span v-if="isTab == '0'">二、请选择数据重复时的处理方式（查重规则：【联系电话】）</span>
                            <span v-if="isTab == '1'">二、请选择数据重复时的处理方式（查重规则：【联系电话】）</span>
                        </div>
                        <div style="padding:10px 10px 10px 30px">
                            <a-select v-model="import_type" style="width: 300px">
                                <a-select-option value="1">
                                    覆盖系统原有数据
                                </a-select-option>
                                <a-select-option value="0">
                                    跳过
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <div>
                        <div class="uploadModal-content-title">
                            <span>三、请选择需要导入的文件</span>
                        </div>
                        <div style="padding:10px 10px 10px 30px">
                            <a-upload
                                name="file"
                                multiple
                                accept=".xls,.xlsx,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                :beforeUpload="beforeUpload"
                            >   
                                <div style="display:flex">
                                    <a-input style="margin-right:10px;width:300px" :value="fileName" disabled />
                                    <a-button><a-icon type="upload" /> 选择文件 </a-button>
                                </div>
                            </a-upload>
                        </div>
                    </div>
                    <div v-if="isTab == '0'">
                        <div class="uploadModal-content-title">
                            <span>四、线索类型</span>
                        </div>
                        <div style="padding:10px 10px 10px 30px">
                            <a-radio-group v-model="in_pool">
                                <a-radio :value="0">
                                当前跟进
                                </a-radio>
                                <a-radio :value="1">
                                线索池
                                </a-radio>
                            </a-radio-group>
                        </div>
                    </div>
                    <!-- <div>
                        <div class="uploadModal-content-title">
                            <span>四、请选择所属校区</span>
                        </div>
                        <div style="padding:10px 10px 10px 30px">
                            <a-select :not-found-content="fetching ? undefined : null" v-model="import_to_studio" allowClear showSearch placeholder="请选择校区" :filterOption="filterOption" style="width: 300px;">
                                <template slot="notFoundContent">
                                    <div style="height:50px">
                                        <vue-element-loading :active="fetching" color="#00cca2" spinner="spinner"/>
                                    </div>
                                </template>
                               <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                            </a-select>
                        </div>
                    </div> -->

                    <div>
                        <div class="uploadModal-content-title">
                            <!-- <span v-if="isTab == '0'">四、请选择负责人（必选）</span> -->
                            <span v-if="isTab == '1'">四、请选择负责人（如不选择，导入的客户将进入公海）</span>
                        </div>
                        <div style="padding:10px 10px 10px 30px">
                            <!-- <a-select v-if="isTab == '0'" default-value="1" style="width: 300px" placeholder="请选择负责人" v-model="import_to_employee" showSearch :filterOption="filterOption">
                                <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                            </a-select> -->
                            <a-select v-if="isTab == '1'" default-value="1" style="width: 300px" placeholder="请选择负责人" v-model="import_to_employee" allowClear showSearch :filterOption="filterOption">
                                <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                            </a-select>
                        </div>
                    </div>

                    <div style="text-align:right">
                        <a-button @click="handleCancel" style='margin-right:10px'>取消</a-button>
                        <a-button @click="toUpload" type="primary">确定</a-button>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane disabled key="3" tab="">
            </a-tab-pane>
            <a-tab-pane disabled key="4" tab="">
            </a-tab-pane>
            <a-tab-pane key="5" disabled tab="导入数据">
                <div style="height:364px;display:flex;justify-content:center;align-items:center">
                    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                    <div>
                        <div class="result" style="text-align:center">
                            <a-result v-if="isUpload" sub-title="上传中...">
                                <template slot="icon">
                                    <a-icon type="loading" :style="{fontSize:'28px',color:'#00cca2'}" />
                                </template>
                                <div>上传中</div>
                                <div v-if="uploadInfo.total" style="text-align:center;margin:10px 0">
                                    共 {{uploadInfo.total}} 条数据, 
                                    已导入<span style="color:#659ded"> {{uploadInfo.done}} </span>条,
                                    <!-- 成功<span style="color:#67c23a"> {{uploadInfo.done}} </span>条,  --> 
                                    <!-- 覆盖<span> {{uploadInfo.cover}} </span>条,  -->
                                    失败/跳过<span style="color:#f56c6c"> {{uploadInfo.error}} </span>条
                                </div>
                            </a-result>
                            <a-result v-if="isSuccess" status="success" title="">
                                <div>已完成</div>
                                <div style="text-align:center;margin:10px 0">
                                    共 {{uploadInfo.total}} 条数据, 
                                    已导入<span style="color:#659ded"> {{uploadInfo.done}} </span>条,
                                    <!-- 成功<span style="color:#67c23a"> {{uploadInfo.done}} </span>条,  -->
                                    <!-- 覆盖<span> {{uploadInfo.cover}} </span>条,  -->
                                    失败/跳过<span style="color:#f56c6c"> {{uploadInfo.error}} </span>条
                                </div>
                            </a-result>
                            <a-result v-if="isCancel" title="">
                                <div>已取消</div>
                                <div style="text-align:center;margin:10px 0">
                                    共 {{uploadInfo.total}} 条数据, 
                                    已导入<span style="color:#659ded"> {{uploadInfo.done}} </span>条,
                                    <!-- 成功<span style="color:#67c23a"> {{uploadInfo.done}} </span>条,   -->
                                    <!-- 覆盖<span> {{uploadInfo.cover}} </span>条,  -->
                                    失败/跳过<span style="color:#f56c6c"> {{uploadInfo.error}} </span>条
                                </div>
                            </a-result>
                            <a-result v-if="isError" status="success" title="">
                                <div >已完成</div>
                                <div style="text-align:center;margin:10px 0">
                                    共 {{uploadInfo.total}} 条数据, 
                                    已导入<span style="color:#659ded"> {{uploadInfo.done}} </span>条, 
                                    <!-- 成功<span style="color:#67c23a"> {{uploadInfo.done}} </span>条,  --> 
                                    <!-- 覆盖<span> {{uploadInfo.cover}} </span>条,  -->
                                    失败/跳过<span style="color:#f56c6c"> {{uploadInfo.error}} </span>条
                                </div>
                                <div v-if='uploadInfo.error>0' style="text-align:center;cursor:pointer;">
                                    <span @click="toDownError" style="border-bottom: 1px solid #00cca2;color:#00cca2;">
                                        <a-icon color='#00cca2' type="download" />
                                        点击下载错误数据
                                    </span>
                                </div>
                            </a-result>
                        </div>
                    </div>
                </div>
                <div v-if="isUpload" style="text-align:right">
                    <a-button @click="toCancel" type="primary">取消导入</a-button>
                </div>
            </a-tab-pane>
        </a-tabs>
    </div>
  </a-modal>
  </div>
</template>
<script>
import url from '@/utils/URL'

export default {
    inject: ['parent'],
    name:'uploadModal',
    data(){
        return{
            loading:false,
            fetching: false,
            confirmLoading: false,
            visible: false,
            isSuccess:false,
            isError:false,
            isUpload:false,
            isCancel:false,
            in_pool:1,
            fileName:'',
            fileList:[],
            activeKey:'2',
            modalTitle:'',
            import_type:'0',
            consultants:[],
            import_to_employee:this.$store.getters.employee_id,
            studios:[],
            //import_to_studio:undefined,
            uploadInfo:{}
        }
    },
    props:['isTab'],
    created() {
        this.visible = true
        this.getStudio()
        this.getConsultant()
        if(this.isTab == '0'){
            this.modalTitle = '导入线索'
        }else{
            this.modalTitle = '导入客户'
        }
    },

    beforeDestroy() {
        this.visible = false
    },
    methods:{
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        async handleOk() {
            this.confirmLoading=true
        },
        handleCancel() {
            this.parent.hideUploadModal()
        },
        async getStudio() {
            this.fetching = true
            let res = await this.$store.dispatch('searchBelongStudioAction', {})
            this.studios = res.data
            this.fetching = false
        },
        async getConsultant() {
            let res = await this.$store.dispatch('searchConsultantAction', {})
            this.consultants = res.data
        },
        beforeUpload(file){
            this.fileName = file.name
            this.fileList = file;
            return false
        },
        tabClick(a){
            if(a == '2'){
                this.activeKey = '2'
            }
        },
        async toUpload(){
            // if(!this.import_to_studio){
            //     this.$message.warning('请选择所属校区!')
            //     return false
            // }
            if(this.isTab == '0' && !this.import_to_employee){
                this.$message.warning('请选择负责人!')
                return false
            }
            const { fileList } = this;
            const formData = new FormData();
            formData.append('import_file', fileList);
            formData.append('import_type', this.import_type);
            formData.append('import_to_employee', this.import_to_employee);
            // formData.append('import_to_studio', this.import_to_studio);
            if(this.fileName){
                this.activeKey = '5'
                this.isUpload = true
                if(this.isTab == '0'){
                    formData.append('in_pool', this.in_pool);
                    await this.$store.dispatch('cLeadsUploadAction', formData)
                    .then(res=>{
                        this.uploadInfo = res.data
                        if(res.data.done >= res.data.total){
                            if(res.data.error > 0){
                                this.isError = true
                                this.isUpload = false
                                this.$message.success('导入完成!')
                            }else{
                                this.isSuccess = true
                                this.isUpload = false
                                this.$message.success('导入完成!')
                                this.parent.getList()
                            }
                        }else{
                            if(this.isCancel){
                                this.isUpload = false
                                res.data.page = -1
                            }
                            this.toLeadsUpload(res.data)
                        }
                    })
                    .catch(err=>{
                        this.$message.error(err.data)
                        this.isUpload = false
                        this.activeKey = '2'
                    })
                }else{
                    await this.$store.dispatch('cCustomerUploadAction', formData)
                    .then(res=>{
                        this.isUpload = true
                        this.uploadInfo = res.data
                        if(res.data.done >= res.data.total){
                            if(res.data.error > 0){
                                this.isError = true
                                this.isUpload = false
                                this.$message.success('导入完成!')
                            }else{
                                this.isSuccess = true
                                this.isUpload = false
                                this.$message.success('导入完成!')
                                this.parent.getList()
                            }
                        }else{
                            if(this.isCancel){
                                this.isUpload = false
                                res.data.page = -1
                            }
                            this.toCustomerUpload(res.data)
                        }
                    })
                    .catch(err=>{
                        this.$message.error(err.data)
                        this.isUpload = false
                        this.activeKey = '2'
                    })
                }
            }else{
                this.$message.error('请选择导入文件!')
            }
            
        },
        async toDown(){
            let res
            if(this.isTab == '0'){
                res = await this.$store.dispatch('cLeadsExampleAction', {})
            }else{
                res = await this.$store.dispatch('cCustomerExampleAction', {})
            }
            const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
            if ('download' in document.createElement('a')) { 
                const link = document.createElement('a')
                if(this.isTab == '0'){
                    link.download = '线索导入模板.xlsx'
                }else{
                    link.download = '客户导入模板.xlsx'
                } 
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href) 
                document.body.removeChild(link)
            } else { //其他浏览器
                navigator.msSaveBlob(blob, fileName)
            }
        },
        async toDownError(){
            let res 
            if(this.isTab == '0'){
                res = await this.$store.dispatch('cLeadsErrorFileAction', {error_file:this.uploadInfo.error_file})
            }else{
                res = await this.$store.dispatch('cCustomerErrorFileAction', {error_file:this.uploadInfo.error_file})
            }
            const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
            if ('download' in document.createElement('a')) { 
                const link = document.createElement('a')
                link.download = '导入错误数据.xlsx'
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href) 
                document.body.removeChild(link)
            } else { //其他浏览器
                navigator.msSaveBlob(blob, fileName)
            }
        },
        async toLeadsUpload(obj){
            obj.import_type = this.import_type
            await this.$store.dispatch('cLeadsUploadAction', obj)
            .then(res=>{
                if(res.data.page !== -1){
                    this.uploadInfo = res.data
                    if(res.data.done >= res.data.total){
                        if(res.data.error > 0){
                            this.isError = true
                            this.isUpload = false
                            this.$message.success('导入完成!')
                        }else{
                            this.isSuccess = true
                            this.isUpload = false
                            this.$message.success('导入完成!')
                            this.parent.getList()
                        }
                    }else{
                        if(this.isCancel){
                            this.isUpload = false
                            res.data.page = -1
                        }
                        this.toLeadsUpload(res.data)
                    }
                }
            })
        },
        async toCustomerUpload(obj){
            obj.import_type = this.import_type
            await this.$store.dispatch('cCustomerUploadAction', obj)
            .then(res=>{
                this.uploadInfo = res.data
                if(res.data.done >= res.data.total){
                    if(res.data.error > 0){
                        this.isError = true
                        this.isUpload = false
                        this.$message.success('导入完成!')
                    }else{
                        this.isSuccess = true
                        this.isUpload = false
                        this.$message.success('导入完成!')
                        this.parent.getList()
                    }
                }else{
                    if(this.isCancel){
                        this.isUpload = false
                        res.data.page = -1
                    }
                    this.toCustomerUpload(res.data)
                }
            })
        },
        toCancel(){
            this.isUpload = false
            this.isCancel = true
        }
    }
}
</script>
<style lang="scss">
    .ant-message{
        z-index:4100
    }
    .ant-select-dropdown{
        z-index: 5000;
    }
    .uploadModal{
        .ant-tabs-bar{
            border-bottom:none
        }
        .ant-upload-list-item{
            display: none;
        }
        .ant-result-content{
            padding: 15px;
        }
    }
</style>